
import './CustomTitle.css'
import BlinkerText from './BlinkerText'


export default function CustomTitle({samples, angle}) {

	return (
		<div style={{'--skAngle': angle}} className='custom-title'>
			<BlinkerText 
				samples={samples} 
				cutPos={0}
			/>
		</div>
	)
}
