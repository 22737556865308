

import { Canvas } from '@react-three/fiber'
import { useEffect, useState, useRef } from 'react'

import Scene from './components/Scene'
import Scrollable from './components/Scrollable'
import ContentSection from './components/ContentSection'
import OutlineRenderer from './components/OutlineRenderer'
import LoadingDots from './components/LoadingDots'
import { sRGBEncoding, ACESFilmicToneMapping, CineonToneMapping, NoToneMapping, LinearEncoding } from 'three'

import TextEffect from './components/TextEffect'
import CustomTitle from './components/CustomTitle'
import BlinkerText from './components/BlinkerText'
import { contentConf, textContent, titlesSamples } from './content-data'

export default function App() {


	const [ scroll, setScroll ] = useState(0)
	const [ isLoaded, setIsLoaded ] = useState(false)
	const [ isAnimated, setIsAnimated ] = useState(false)
	const [ firstScroll, setFirstScroll ] = useState(false)
	const [ onLoadText, setOnLoadText ] = useState('¡Welcome to my portfolio!')

	return (
		<>	
			<div className='webgl'>
				<Canvas 
					orthographic
					gl={{
						toneMapping: NoToneMapping,
						outputEncoding: LinearEncoding
					}}
					camera={{
						far: 100,
						near: 0.01,
						position: [
							5.6 * Math.sin(45 * Math.PI / 180),
							4,
							5.6 * Math.cos(45 * Math.PI / 180),
						],
					}}
				>
					<Scene 
						onLoad={e=>setIsLoaded(true)} 
						onAnim={e=>setIsAnimated(true)}
						onScroll={e=>setFirstScroll(true)} 
					/>
					<color attach="background" args={["#3d393a"]} />
					<OutlineRenderer defaultThickness={0.002}  />
				</Canvas>
			</div>
			<span 
				className={`scroll-hint-text ${
					isLoaded?!firstScroll?'show':'':''
				}`}
			>
				Click/touch to continue!
			</span>
			<Scrollable onScroll={setScroll} isACtive={isAnimated} >
				<ContentSection>
					{isAnimated &&
					<> 
					<BlinkerText 
						samples={[
							"I'm a\ncreative\ndeveloper", 
							"I'm a\ncivil\nengineer",
							"I'm a\nfullstack\ndeveloper",
							"I'm a\n3D designer",
							"I'm a\nguitar\nplayer",
							"I'm a\ndog lover",
							"I'm a\ncyclist",
							]} 
						cutPos={4}
					/>
					<div className='scroll-hint'>	
					</div>
					</>
					}
				</ContentSection>
				<ContentSection />
				<ContentSection />
				<ContentSection {...contentConf.bio} scroll={scroll}>
					<CustomTitle samples={titlesSamples.bio} angle={-10} />
					{textContent.bio}
				</ ContentSection>
				<ContentSection />
				<ContentSection />
				<ContentSection {...contentConf.projects} scroll={scroll}>
					<CustomTitle samples={titlesSamples.projects} angle={0} />
					{textContent.projects}
				</ ContentSection>
				<ContentSection />
				<ContentSection {...contentConf.challenges} scroll={scroll}>
					<CustomTitle samples={titlesSamples.challenges} angle={10} />
					{textContent.challenges}
				</ ContentSection>
				<ContentSection />
				<ContentSection />
				<ContentSection {...contentConf.skills} scroll={scroll}>
					<CustomTitle samples={titlesSamples.skills} angle={-10} />
					{textContent.skills}
				</ ContentSection>
				<ContentSection />
				<ContentSection />
				<ContentSection {...contentConf.hobbies} scroll={scroll}>
					<CustomTitle samples={titlesSamples.hobbies} angle={10} />
					{textContent.hobbies}
				</ContentSection>
				<ContentSection />
				<ContentSection />
				<ContentSection {...contentConf.social} scroll={scroll}>
					<CustomTitle samples={titlesSamples.social} angle={-10} />
					{textContent.social}
				</ContentSection>
				<ContentSection h={1}/>
			</Scrollable>
			<div className='onload-div'>
				{isLoaded && 
					<TextEffect text={onLoadText} hide={firstScroll}/>
				}
			</div>
			<LoadingDots isLoaded={isLoaded} />
		</>
	)
}

