

import './style.css'
import App from './App'
import reactDOM from 'react-dom/client' 
import ViewState from './contexts/view/ViewState'
import ClientState from './contexts/client/ClientState'

const root = reactDOM.createRoot(document.querySelector('#root'))

root.render(
	<ClientState>
	<ViewState>
	<App />
	</ViewState>
	</ClientState>
)