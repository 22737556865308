

import { primitive, useEffect, useRef, useState } from 'react'
import { useGLTF, useTexture, useAnimations } from '@react-three/drei'
import gsap from 'gsap'
import { MeshBasicMaterial, LoopOnce } from 'three'


export default function Dog({show}) {

	const [ scale, setScale ] = useState(0.001)
	const alphaMap = useTexture('./textures/dogShadowAlpha.png', 
		(map) => {
			map.flipY = false
			// map.encoding = sRGBEncoding
		})

	const dog = useGLTF('/models/dog/Dog.glb')
	const animations = useAnimations(dog.animations, dog.scene)

	useEffect(()=>{
		const alphaMaterial = new MeshBasicMaterial({alphaMap: alphaMap, transparent: true, color: '#000'})

		let children = [...dog.scene.children]
		children.forEach(child=>{
			if (child.name==='Armature') {
				child.children.at(-1).children[0].material = new MeshBasicMaterial({color: '#fff'})
				child.children.at(-1).children[1].material = new MeshBasicMaterial({color: '#000'})
			}
			if (child.name==='Plane') child.material = alphaMaterial
		})
	}, [])

	useEffect(()=>{
		if (!show) return
		let scaleObject = {value: scale}
		gsap.to(scaleObject, {
			value: 1, 
			duration: 0.5, 
			onUpdate: function() {
				setScale(scaleObject.value)
			}
		})
		let stand = animations.actions['dog-stand']
		stand.setLoop(LoopOnce, 1) 
        stand.clampWhenFinished = true
        stand.play()
        setTimeout(()=>{
        	animations.actions['head-moveRL'].play()
        	animations.actions['tail-moveRL'].play()
        }, stand._clip.duration * 1000)
	}, [show])

	return (
		<primitive object={dog.scene} scale={scale} position-z={-1.33943} />
	)

}