
import { useGLTF, useTexture, useAnimations } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'
import { Suspense, primitive, useEffect, useState, useRef } from 'react'
import { MeshBasicMaterial, sRGBEncoding, LoopOnce, Group, CanvasTexture } from 'three'
import MatrixCanvas from '../utils/matrix-canvas'
import { useViewContext } from '../contexts/view/ViewState'
import { useClientContext } from '../contexts/client/ClientState'
import Dog from './Dog'
import gsap from 'gsap'


export default function FrontModel({callbacks}) {

	const fences = useRef(Array())
	const monitor = useRef(new Group())
	const { sizes } = useClientContext()
	const fConf = useRef({s1: 1, y1: 0.2})
	const { loadView, watcher } = useViewContext() 
	const [ showDog, setShowDog ] = useState(false)
	const [ onlyMonitor, setOnlyMonitor ] = useState(true)

	const [ bakedMap, carpetMap, alphaMap, floorMap ] = useTexture([
		'./textures/bakedMap.png', 
		'./textures/carpetMap.png', 
		'./textures/alphaMap.png', 
		'./textures/floorAlpha.png'
		], 
		([...maps]) => {
			maps.forEach(map=>{
				map.flipY = false
				// map.encoding = sRGBEncoding
			})
		})

	const model = useGLTF('/models/scene/allScene.glb')
	const animations = useAnimations(model.animations, model.scene)

	useEffect(()=>{
		setupAnimations()
		assignMaterials()
		setTimeout(()=>{
			callbacks.onLoad()
			loadView('load', true)
		}, 500)
	}, [])

	const setupAnimations = () => {
		Object.entries(animations.actions).forEach(([name, action])=>{
            if (
            	name.includes('initAnim')||
                name.includes('backAnim')||
                name.includes('onFences')||
                name.includes('phoneAnim')||
                name.includes('monitorSpin')||
                name.includes('monitorScale')) {
                action.setLoop(LoopOnce, 1) 
                action.clampWhenFinished = true   
            }
            if (name.includes('monitorScale')) setTimeout(()=>{
            	action.play()
            	setTimeout(()=>{
            		addEventListener('click', startAnimations)
            	}, action._clip.duration * 1000)
            }, 500)
		})
	}

	const assignMaterials = () => {
		// Matrix Texture
		const matrixCanvas = new MatrixCanvas()
		const matrixTexture = new CanvasTexture(matrixCanvas.matrixCanvas)
		matrixCanvas.texture = matrixTexture

		// Materials
		const bakedMaterial = new MeshBasicMaterial({map: bakedMap})
		const blackMaterial = new MeshBasicMaterial({color: '#000'})
		const carpetMaterial = new MeshBasicMaterial({map: carpetMap})
		const alphaMaterial = new MeshBasicMaterial({alphaMap: alphaMap, 
			transparent: true, color: '#000', depthWrite: false})
		const floorMaterial = new MeshBasicMaterial({alphaMap: floorMap, 
			transparent: true, color: '#000', depthWrite: false})
		const matrixMaterial = new MeshBasicMaterial({map: matrixTexture})

		// Material Assignation
		let fenceArray = []
		let children = [...model.scene.children]
		children.forEach(child=>{
			if (child.name.includes('FloorShadow')) child.material = floorMaterial
			else if (child.name.includes('Shadow')) child.material = alphaMaterial
			else if (child.name.includes('carpet')) child.material = carpetMaterial
			else if (child.name.includes('justBlack')) child.material = blackMaterial
			else if (child.name.includes('Screen')) child.material = matrixMaterial
			else child.material = bakedMaterial
			if (child.name.includes('monitor')||
				child.name.includes('monitorScreen')) {
				monitor.current.add(child)
			}
			if (child.name.includes('fence')) fenceArray.push(child)
		})
		fences.current = [...Array(fenceArray.length)].map((v, i)=>{
			return fenceArray.find(obj=>obj.name.includes(`fence${i+1}_`))
		})
	}

	const startAnimations = () => {
		callbacks.onScroll()
		animations.actions.monitorScale.stop()
		const spin = animations.actions.monitorSpin
		spin.play()
		setTimeout(()=>{
			Object.entries(animations.actions).forEach(([name, action])=>{
				if (name.includes('initAnim')) action.play()
			})
			setOnlyMonitor(false)
			loadView('core', false, null, 0, "power2.inOut")
			watcher.current = [...watcher.current, {li: 16, ls: 16, cb: animBack}]
			watcher.current = [...watcher.current, {li: 19, ls: 19, cb: animPhone}]
			setTimeout(runActions, animations.actions['initAnim-fan2']._clip.duration * 1000)
		}, spin._clip.duration * 500)
		removeEventListener('click', startAnimations)
	}

	const runActions = () => {
		callbacks.onAnim()
		Object.entries(animations.actions).forEach(([name, action])=>{
			if (name.includes('Action')) action.play()
		})
	}

	const animBack = (s) => {
		Object.entries(animations.actions).forEach(([name, action])=>{
			if (name.includes('backAnim')) action.play()
		})
		setTimeout(()=>{
			animFences()
		}, animations.actions.backAnim_fences._clip.duration * 1000)
	}

	const animPhone = (s) => {
		Object.entries(animations.actions).forEach(([name, action])=>{
			if (name.includes('phoneAnim')) action.play()
		})
	}

	const animFences = () => {
	    for (let id=1; id<=fences.current.length; id++) {
	        let fence = fences.current.find(fence=>fence.name===`fence${id}_ST`)
	        gsap.to(fence.position, {
	        	y: fConf.current.y1, 
	        	duration: 0.5, delay: 
	        	id * 0.05
	        })
	        gsap.to(fence.scale, {
	        	x: fConf.current.s1, 
	        	y: fConf.current.s1, 
	        	z: fConf.current.s1, 
	        	duration: 0.5, delay: 
	        	id * 0.05
	        })
	    }
	    setTimeout(putDoor, fences.current.length * 0.05 * 1000)
	}

	const putDoor = () => {
		Object.entries(animations.actions).forEach(([name, action])=>{
			if (name.includes('onFences')) action.play()
	        if (name.includes('STaction')) setTimeout(()=>{
	            action.play()
	        }, 1500)				
		})
	    setShowDog(true)
	}

	return (
		<>
			{!onlyMonitor && <primitive object={model.scene} />}
			<primitive object={monitor.current} />
			<Dog show={showDog} />
		</>
	)

}