
const skills = ['HTML / CSS / JS', 'React', 'Webpack', 'ThreeJS', 'NodeJS', 'Express', 'MongoDB / Mongoose', 'Git', 'Python', 'Kivy', 'VBA', 'Power Automate', 'Blender', 'React-three-fiber']

const interests = [
	{
		label:'Web Dev.',
		icon: <i className="fa-solid fa-computer"></i>
	},
	{
		label:'Game Dev.',
		icon: <i className="fa-solid fa-gamepad"></i>
	},	
	{
		label:'3D Modeling',
		icon: <i className="fa-brands fa-uncharted"></i>
	},
	{
		label:'Inmersive Apps',
		icon: <i className="fa-solid fa-vr-cardboard"></i>
	},
	{
		label:'Data Science',
		icon: <i className="fa-solid fa-circle-nodes"></i>
	},
	{
		label:'Classic Guitar',
		icon: <i className="fa-solid fa-guitar"></i>
	},
	{
		label:'Animals',
		icon: <i className="fa-solid fa-paw"></i>
	},
	{
		label:'Keep Learning',
		icon: <i className="fa-solid fa-graduation-cap"></i>
	}
]

const textContent = {
	bio: 
		<p>I'm Mateo Martinez Herrera, a civil engineer with 10+ years of experience focused mostly in dam design. Throughout my career I've adquired a specific set of skills in the programming field applied to process automation, design optimization and 3D modeling.<br />Most of my projects involved code developing in multiple programming languages, such as Fortran, VBA, Python and, most recently, Javascript. Therefore, I became very sensitive at solving algorithmic problems, finding unexpected solutions for highly advanced problems regarding engineering and process automation.<br/>I'm confident working in organized teams, sharing a well structured developing methodology, furthermore, I've shared leadership in a multidiciplinary specialized team focused on process automation and dam suirveillance optimization of important hydroelectric projects.<br/>If you want to know more about my working experience you can take a look to my <a href='https://Mamartinezh.github.io/CV/' target='BLANK'>C.V.</a> or download my <a href='/documents/Mateo-Martinez-Herrera-Resume.pdf' download='Mateo-Martinez-Herrera-Resume.pdf' target='BLANK'>Résumé PDF</a><br/>Aditionally, if you want to know more about myself you can take a look to my <a href='https://autobiography-opal.vercel.app/' target='BLANK'>interactive autobiography.</a></p>,
	projects: 
		<div className='projects-content'>
			<p>Here are some of the projects I'm developing, as well as some ideas for future projects.</p>
			<div className='projects'>
				<a style={{background: '#252525'}} text-name='Biography' target='BLANK' href='https://autobiography-opal.vercel.app/'>
					<img src='/images/autobiography.webp'/>
				</a>
				<a style={{background: '#1f1f1f'}} text-name='CHANADI Ecommerce' text-info='In progress' target='BLANK' href='https://chanadi-web.vercel.app/'>
					<img  src='/images/chanadi.webp'/>
				</a>
				<a style={{background: '#19b7a2'}} text-name='Journal App' target='BLANK' href='https://journal-app-3da8.onrender.com/'>
					<img src='/images/journal-app.webp'/>
				</a>
				<a style={{background: '#252525'}} text-name='Martinez Experience' target='BLANK' href='https://www.martinezexperiencelanzamiento.xyz//'>
					<img src='/images/martinez-experience.webp'/>
				</a>
				<a style={{background: '#262626'}} text-name='Movies App' target='BLANK' href='https://movies-app-nine-ashen.vercel.app/'>
					<img  src='/images/movies-app.png'/>
				</a>
				<a style={{background: '#161616'}} text-name='RAWG clone Games App' target='BLANK' href='https://rawg-clone-lilac.vercel.app/'>
					<img src='/images/games-app.png'/>
				</a>
				<a style={{background: '#262626'}} text-name='Starwars App' target='BLANK' href='https://starwars-app-lake.vercel.app/'>
					<img src='/images/starwars-app.png'/>
				</a>
				<a style={{background: '#262626'}} text-name='Simpsons Quotes' target='BLANK' href='https://simpsons-quotes-6ovuspc1s-mateoeslaley.vercel.app/'>
					<img  src='/images/simpsons-quotes.png'/>
				</a>
			</div>
		</div>,
	challenges: 
		<div className='projects-content'>
			<p>Here are some smaller deployments, some coding challenges and projects made just for having fun or learning!</p>
			<div className='projects'>
				<a style={{background: '#808080'}} text-name='Quiz App' target='BLANK' href='https://quiz-app-iekt.onrender.com/'>
					<img src='/images/quiz-app.png'/>
				</a>
				<a style={{background: '#4d8844'}} text-name='Snake game' target='BLANK' href='https://snake-game-p21e.onrender.com/'>
					<img src='/images/snake-game.webp'/>
				</a>
				<a style={{background: '#87cefa'}} text-name='Ball game' target='BLANK' href='https://ball-game.onrender.com/'>
					<img src='/images/ball-game.webp'/>
				</a>
				<a style={{background: '#252525'}} text-name='Pokémon game' target='BLANK' href='https://pokemon-game-vwnu.onrender.com/'>
					<img src='/images/pokemon-game.webp'/>
				</a>
				<a style={{background: '#252525'}} text-name='Weather App' target='BLANK' href='https://weather-app-z1qk.onrender.com/'>
					<img src='/images/weather-app.webp'/>
				</a>
				<a style={{background: '#262626'}} text-name='Card slider' text-info='No responsive' target='BLANK' href='https://card-slider.onrender.com/'>
					<img src='/images/card-slider.webp'/>
				</a>
				<a style={{background: '#e6ecee'}} text-name='Calculator' target='BLANK' href='https://calculator-h74g.onrender.com'>
					<img src='/images/calculator.png'/>
				</a>
				<a style={{background: '#4b4b59'}} text-name='Calendar' target='BLANK' href='https://calendar-02cu.onrender.com'>
					<img src='/images/calendar.png'/>
				</a>
			</div>
		</div>,
	skills: <div className='skills-content'>
				<div className='skills'>
					<p>As I said, working in the engineering field allowed me to develop a specific set of coding skills, here are some of those.</p>	
					<br />		
					<div className='skills-div'>
						{skills.map(skill=>
							<p key={skill}>{skill}</p>
						)}
					</div>
				</div>
				<div className='interests'>
					<br />
					<br />
					<p>Furthermore, these are some of my interests and things I would like to learn more about.</p>		
					<br />	
					<div className='interests-div'>
						{interests.map(interest=>
							<div key={interest.label} className='interest'>
							{interest.icon}
							<p>{interest.label}</p>
							</div>
						)}
					</div>
				</div>
			</div>,
	hobbies: <p>In my spare time I love sharing with my dogs, specially walking and going outdoors for connecting with nature. My favourite music genres are classical music and blues, that's why I really enjoy playing the classical guitar with a little bit of blues influence.<br />I love riding my bike for long distances, taking pleasure of secluded locations and remote areas with nature predominance; the loneliness of long trips by bike give me calm and a peaceful feeling. <br />Working out has always been an importan part of my life, even thought inconsistency keep me from reaching my goals, I really enjoy working out just for the sensation of agitation and the benefits from it.</p>,
	social: 
		<div className='social'>
			<a href='https://www.linkedin.com/in/mateo-martínez-herrera-developer/' target='BLANK'>
				<i className="fa-brands fa-linkedin" />
			</a>
			<a href='https://www.instagram.com/mateomartinezherrera/' target='BLANK'>
				<i className="fa-brands fa-square-instagram"></i>
			</a>
			<a href='https://github.com/Mamartinezh/' target='BLANK'>
				<i className="fa-brands fa-square-github"></i>
			</a>
			<a href='https://wa.me/573194243643' target='BLANK'>
				<i className="fa-brands fa-square-whatsapp"></i>
			</a>
		</div>

}

const contentConf = {
    bio: {h: 2, p: 'r', s: 3, e: 5, w: 0.5, bg: '#8f2d56'},
    projects: {h: 2, p: 'l', s: 7, e: 9, w: 0.5, bg: '#218380'},
    challenges: {h: 2, p: 'r', s: 10, e: 12, w: 0.5, bg: '#8f2d56'},
    skills: {h: 2, p: 'l', s: 14, e: 16, w: 0.5, bg: '#e19f36'},
    hobbies: {h: 2, p: 'r', s: 18, e: 20, w: 0.5, bg: '#d81159'},
    social: {h: 2, p: 'l', s: 22, e: 24, w: 0.5, bg: '#73d2de'},
}


const titlesSamples = {
    bio: ["About Me", "My Bio", "Background"],
    projects: ["My Projects", "Awesome\nimmersive\nexperiences"],
    challenges: ["Some coding\nchallenges", "And some\nfun!"],
    skills: ["My skills", "Some\ninterests", "And\nstrengths!"],
    hobbies: ["My hobbies", "My favourite\nstuff!"],
    social: ["contact me", "My social\nmedia"],    
}

export {
	contentConf,
	textContent,
	titlesSamples,
}