
import { useEffect, useRef, useState } from 'react'
import { scrolls } from '../view-settings'
import { useThree } from '@react-three/fiber'
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar'
import { useViewContext } from '../contexts/view/ViewState'
import { useClientContext } from '../contexts/client/ClientState'

// Enabling smoooth scrollbar plugin
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    open: false,
  };

  transformDelta(delta) {
    return this.options.open ? { x: 0, y: 0 } : delta;
  }
}
Scrollbar.use(ModalPlugin);

// scrollbar settings
const initSettings = {
	damping: 0.05,
	alwaysShowTracks: true
}

// reset scrolling on refresh
window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}

export default function Scrollable({children, ...props}) {

	const scrollY = useRef(0)
	const sIdx = useRef(null)
	const angle = useRef(null)
	const Scroll = useRef(null)
	const scrollableElement = useRef()
	const settings = useRef(initSettings)

	const { aspect, sizes } = useClientContext()
	const { updateSettings, setNewAsRefView, lockScroll, watcher } = useViewContext()

	useEffect(()=>{
		Scroll.current = Scrollbar.init(scrollableElement.current, settings)
		Scroll.current.addListener(calcScrollPercentage)
		return ()=>Scroll.current.removeListener(calcScrollPercentage)
	}, [])

	useEffect(()=>{
		Scroll.current.updatePluginOptions('modal', { open: lockScroll }); 
	}, [lockScroll])

	const calcScrollPercentage = (s) => {
    const h = Math.max(sizes.current.height, 600) 
    let pctg = s.offset.y / h
		props.onScroll({y: pctg, w: sizes.current.width})
    checkWatchers(pctg, s)
    let scrollId = scrolls.findIndex(scroll=>scroll.ls<=pctg&&scroll.li>=pctg)
    if (scrollId===-1) return
    let scroll = scrolls.at(scrollId)
    if (sIdx.current===null) sIdx.current = scrollId
    if (sIdx.current!==scrollId) {
      if ((scrolls.at(sIdx.current).dir>0&&scrollId>sIdx.current)||
      	(scrolls.at(sIdx.current).dir<0&&scrollId<sIdx.current)) {
    		scrolls.at(sIdx.current).dir *= -1
    		setNewAsRefView()
    	}
  		sIdx.current=scrollId
    }
    let dir = scrollY.current-s.offset.y>0
    scrollY.current = s.offset.y
    pctg = (s.offset.y - scroll.ls * h) / (h * (scroll.li - scroll.ls))
    pctg = scroll.dir<0?1-pctg:pctg
	  updateSettings(pctg, scrolls.at(sIdx.current).dir>0?scroll.to:scroll.from)
	}

	const checkWatchers = (pctg, s) => {
		watcher.current.forEach(({ li, ls, cb, args }, id)=>{
			if (pctg>=li) cb(s, ...(args??[]))
			if (ls&&pctg>=ls) watcher.current.splice(id, 1)
		})
	}

	return  (
		<div 
			style={{
				zIndex: '30',
				width: '100%',
				height: '100vh',
				overflow: 'scroll',
				opacity: props.isACtive?1:0
			}}
			ref={scrollableElement} 
			className='scrollable'
		>
			{children}	
		</div>
	)
}