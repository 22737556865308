
import { extend, useFrame, useThree } from '@react-three/fiber'
import { useRef, useEffect, useState, Suspense } from 'react'
import CustomObject from './CustomObject'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { useViewContext } from '../contexts/view/ViewState'
import { useClientContext } from '../contexts/client/ClientState'

import FrontModel from './FrontModel'

extend({ OrbitControls })

export default function Scene({onLoad, onScroll, onAnim}) {

	const control = useRef()
	const mainGroup = useRef()
	const sceneY0 = useRef(0)
	const fitZoom = useRef(null)
	const floorMaterial = useRef()
	const { camera, gl } = useThree()
	const { viewSettings } = useViewContext()
	const { cursor, cursorSettings, sizes, domRef } = useClientContext()

	useFrame((state, delta)=>{
		control.current.update()

	    if (cursorSettings.isOn) {
	        mainGroup.current.position.x += cursor.current.left * cursorSettings.damping
	        mainGroup.current.position.z -= cursor.current.left * cursorSettings.damping
	        cursor.current.left += - cursor.current.left * cursorSettings.damping
	    	cursor.current.offset = cursor.current.x * cursorSettings.delta - cursor.current.left
	    }

	})

	useEffect(()=>{
		control.current.target.y = 1
	}, [])

	useEffect(()=>{
		let zoom, zoomH, zoomV
		let dh = sizes.current.width<500?0.1:0.5
		zoomH = (sizes.current.width)  / (Math.sqrt(8) + dh)
		zoomV = (sizes.current.height) / 3.36
		zoom = zoomH?zoomV?zoomH<zoomV?zoomH:zoomV:zoomH:zoomV?zoomV:null
		if (!zoom) return fitZoom.current = null
		camera.zoom = zoom
		fitZoom.current = zoom
		let factor = sizes.current.height / (sizes.current.height) 
		camera.updateProjectionMatrix()
		updateView()
	}, [sizes.current])

	useEffect(()=>{
		updateView()
	}, [viewSettings])

	const updateView = () => {
		let offset = cursor.current.offset
		let dh = (sizes.current.width - fitZoom.current * viewSettings.ss * Math.sqrt(8) - 100) * viewSettings.c * Math.cos(45 * Math.PI / 180) / fitZoom.current 
		mainGroup.current.position.x = dh + offset + viewSettings.sh
		mainGroup.current.position.z = -dh - offset - viewSettings.sh
		mainGroup.current.position.y = viewSettings.sy
		mainGroup.current.rotation.y = viewSettings.st * Math.PI / 180
		mainGroup.current.scale.set(viewSettings.ss, viewSettings.ss, viewSettings.ss)
		floorMaterial.current.color.set(viewSettings.fc)
	}

	return (
		<>
			<orbitControls ref={control} args={[camera, gl.domElement]} damping={0.05} enableDamping enabled={false} />
			<ambientLight intensity={1}/>
			<group ref={ mainGroup }>
				<Suspense>
					<FrontModel 
						callbacks={{
							onLoad: onLoad, 
							onAnim: onAnim,
							onScroll: onScroll
						}} />
				</Suspense>
				<mesh rotation-x={-Math.PI * 0.5} position-y={-0.3} >
					<planeGeometry args={[ 1000, 1000 ]}/>
					<meshBasicMaterial ref={floorMaterial} color='#3d393a' />
				</mesh>
			</group>
		</>
	)
}