
import './ContentSection.css'
import { useEffect, useRef, useState }  from 'react'

export default function ContentSection({children, bg, scroll=null, s, e, h=1, p='c', w='1'}) {

	const div = useRef()
	const [ radius, setRadius ] = useState()

	useEffect(()=>{
		if (!scroll||scroll.y<s-1||scroll.y>e) return 
		let botR = Math.min(scroll.y- (e - 1), 1)	
		let topR = 1 - Math.min(scroll.y - (s - 1), 1)
		setRadius({
			'--tl': p==='r'?topR:0,
			'--tr': p==='l'?topR:0,
			'--br': p==='l'?botR:0,
			'--bl': p==='r'?botR:0,
		})
	}, [scroll])

	return (
		<div ref={div}
			style={{
				width: `${w * 100}%`,
				left: `${p==='l'?-25:p==='r'?25:0}%`,
				'--h': h,
				background: bg,
				...radius,
				'--w': scroll?.w??0,
				outline: scroll?'5px solid #000':null
			}}
			className='content-section'>
			{children}
		</div>
	)
}

