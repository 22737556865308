
import { useRef, useEffect, useState } from 'react'
import { useContext, createContext } from 'react'
import { initCursorSettings, views } from '../../view-settings'

const ClientContext = createContext()

const useClientContext = () => {
	return useContext(ClientContext)
}

export default function ClientState({children}) {

	const domRef = useRef()
	const cursor = useRef({x: 0, left: 0, offset: 0})
	const aspect = useRef(window.innerWidth / window.innerHeight)
	const sizes = useRef({width: window.innerWidth, height: window.innerHeight})
	const view = useRef(views[Object.keys(views).reduceRight((a,b)=>
			b>sizes.current.width?b:a,0)])
	const [ cursorSettings, setCursorSettings ] = useState(initCursorSettings)

	useEffect(()=>{
		domRef.current = document.querySelector('.webgl')
		updateAspect()
		addEventListener('resize', updateAspect)
		addEventListener('mousemove', updateCursor)
		return () => {
			removeEventListener('resize', updateAspect)
			removeEventListener('mousemove', updateCursor)
		}
	}, [])

	const updateCursor = (e) => {
	    if (!cursorSettings.isOn) return
	    if (('ontouchstart' in window) ||
     		(navigator.maxTouchPoints > 0) ||
     		(navigator.msMaxTouchPoints > 0)) {
	    	setCursorSettings(prev=>({...prev, isOn: false}))
	    }
	    let posX =  e.clientX / sizes.current.width - 0.5
	    cursor.current.left += (posX - cursor.current.x) * cursorSettings.delta
	    cursor.current.x = posX
	}


	const updateAspect = (e) => {
		sizes.current = {width: domRef.current.clientWidth, height: domRef.current.clientHeight}
		aspect.current = sizes.current.width / aspect.current.height
		view.current = getView()
	}

	const getView = () => {
		return views[Object.keys(views).reduceRight((a,b)=>
			b>sizes.current.width?b:a,0)]
	}

	return (
		<ClientContext.Provider 
			value={{
				view,
				sizes,
				cursor,
				aspect,
				domRef,
				cursorSettings,
				setCursorSettings
			}} 
		>
			{children}
		</ClientContext.Provider>
	)
}

export { ClientContext, useClientContext }