
const views = {
    500: {
        init: {st: 0, ss: 2, sy: 0, c: 0.5, sh: 0, fc: '#383558', i: 1},
        load: {st: 0, ss: 2.5, sy: -1, c: 0, sh: 0, fc: '#3d393a', i: 2},
        core: {st: 0, ss: 1, sy: 0.3, c: 0, sh: 0, fc: '#3d393a', i: 3},
        left: {st: 0, ss: 0.8, sy: 0.3, c: -0.5, sh: 0, fc: '#a78126', i: 4},
        comp: {st: 0, ss: 2.2, sy: -1.5, c: 0.5, sh: 1.5, fc: '#665e59', i: 5},
        arca: {st: 0, ss: 2.2, sy: -1.5, c: -0.5, sh: -0.5, fc: '#665e59', i: 6},
        hobb: {st: 0, ss: 2.2, sy: 0.3, c: 0.5, sh: 0, fc: '#2d6f38', i: 7},
        dogs: {st: -110, ss: 1.8, sy: 1, c: 0, sh: -0.5, fc: '#2d1e26', i: 8},
        phon: {st: -180, ss: 1.8, sy: 1.5, c: 0.5, sh: 1, fc: '#8d997a', i: 9}  
    },
    1000: {
        init: {st: 0, ss: 1.5, sy: 0, c: 0.5, sh: 0, fc: '#383558', i: 1},
        load: {st: 0, ss: 1.5, sy: -0.2, c: 0, sh: 0, fc: '#3d393a', i: 2},
        core: {st: 0, ss: 1, sy: 0.3, c: 0, sh: 0, fc: '#3d393a', i: 3},
        left: {st: 0, ss: 0.8, sy: 0.3, c: -0.5, sh: 0, fc: '#a78126', i: 4},
        comp: {st: 0, ss: 2.2, sy: -1.5, c: 0.5, sh: 1.5, fc: '#665e59', i: 5},
        arca: {st: 0, ss: 2.2, sy: -1.5, c: -0.5, sh: -0.5, fc: '#665e59', i: 6},
        hobb: {st: 0, ss: 2.2, sy: 0.3, c: 0.5, sh: 0, fc: '#2d6f38', i: 7},
        dogs: {st: -110, ss: 1.8, sy: 1, c: 0, sh: -1.5, fc: '#2d1e26', i: 8},
        phon: {st: -180, ss: 1.8, sy: 1.5, c: 0.5, sh: 1.4, fc: '#8d997a', i: 9}  
    },
    100000: {
        init: {st: 0, ss: 1.5, sy: 0, c: 0.5, sh: 0, fc: '#383558', i: 1},
        load: {st: 0, ss: 1.5, sy: -0.2, c: 0, sh: 0, fc: '#3d393a', i: 2},
        core: {st: 0, ss: 1, sy: 0.3, c: 0, sh: 0, fc: '#3d393a', i: 3},
        left: {st: 0, ss: 0.8, sy: 0.3, c: -0.5, sh: 0, fc: '#a78126', i: 4},
        comp: {st: 0, ss: 2.2, sy: -1.5, c: 0.5, sh: 1.5, fc: '#665e59', i: 5},
        arca: {st: 0, ss: 2.2, sy: -1.5, c: -0.5, sh: -0.5, fc: '#665e59', i: 6},
        hobb: {st: 0, ss: 2.2, sy: 0.3, c: 0.5, sh: 0, fc: '#2d6f38', i: 7},
        dogs: {st: -110, ss: 1.8, sy: 1, c: -0.5, sh: -1.2, fc: '#2d1e26', i: 8},
        phon: {st: -180, ss: 2.2, sy: 1.5, c: 0.5, sh: 1.2, fc: '#8d997a', i: 9} 
    }
}


const scrolls = [
    {li: 2, ls: 1, to: 'left', from: 'core', dir: 1},
    {li: 6, ls: 5, to: 'comp', from: 'left', dir: 1},
    {li: 9, ls: 8, to: 'arca', from: 'comp', dir: 1},
    {li: 13, ls: 12, to: 'hobb', from: 'arca', dir: 1},
    {li: 17, ls: 16, to: 'dogs', from: 'hobb', dir: 1},
    {li: 21, ls: 20, to: 'phon', from: 'dogs', dir: 1},
    {li: 25, ls: 24, to: 'phon', from: 'phon', dir: 1},
]

const initCursorSettings = {
    isOn: true,
    delta: 0.5,
    damping: 0.01
}

export { views, scrolls, initCursorSettings }