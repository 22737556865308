

import { useRef, useEffect, useState } from 'react'
import { useContext, createContext } from 'react'
import { views } from '../../view-settings'
import { useClientContext } from '../client/ClientState'
import gsap from 'gsap'

import lerpColor from '../../utils/color-lerp'

const ViewContext = createContext()

const useViewContext = () => {
	return useContext(ViewContext)
}

export default function ViewState({children}) {

	const { cursor, setCursorSettings, view } = useClientContext()
	const watcher = useRef([])
	const refView = useRef(view.current.init)
	const newView = useRef(view.current.init)
	const intValues = useRef({pctg: 0, to: 'init'})
	const [ lockScroll, setLockScroll ] = useState(true)
	const [ viewSettings, setViewSettings ] = useState(null)

	useEffect(()=>{
		setViewSettings(interpolateSettings(0, 'init'))
	}, [])

	useEffect(()=>{
		interpolateSettings(intValues.current.pctg, intValues.current.to)
	}, [view])

	const interpolateSettings = (pctg, to) => {
		const aspect = window.innerWidth / window.innerHeight
		let settings = {
			c: refView.current.c - (refView.current.c - view.current[to].c) * pctg,
			i: refView.current.i - (refView.current.i - view.current[to].i) * pctg,
			ss: refView.current.ss - (refView.current.ss - view.current[to].ss) * pctg,
			sy: refView.current.sy - (refView.current.sy - view.current[to].sy) * pctg,
			sh: refView.current.sh - (refView.current.sh - view.current[to].sh) * pctg,
			st: refView.current.st - (refView.current.st - view.current[to].st) * pctg,
			fc: lerpColor(refView.current.fc, view.current[to].fc, pctg)
		}
		updateNewView(settings)
		intValues.current = {pctg, to}
		return settings
	}

	const updateSettings = (pctg, to) => {
		setViewSettings(interpolateSettings(pctg, to))
	}

	const updateNewView = (settings) => {
	    newView.current = {
	    	...refView.current,
	    	...settings,
	    }
	}

	const setNewAsRefView = () => {
		refView.current = newView.current
	}

	const loadView = (to, keepLock = true, callback = null, delay = 0, ease='power1.out') => {
		setLockScroll(prev=>true)
		let base = newView.current.i
		let object = {i: base}
	    gsap.to(object, {
	        i: view.current[to].i,
	        duration: 2,
	        delay,
	        ease,
	        onUpdate: function() {
	        	let pctg = (object.i - base)/(view.current[to].i-base)
	        	if (pctg) updateSettings(pctg, to)
	        },
	    	onComplete: function() {
	    		setNewAsRefView()
	    		if (!keepLock) setLockScroll(prev=>false)
	    	}
	    })       
	}

	return (
		<ViewContext.Provider 
			value={{
				watcher,
				refView,
				loadView,
				lockScroll,
				viewSettings,
				updateSettings,
				setNewAsRefView,
			}} 
		>
			{children}
		</ViewContext.Provider>
	)
}

export { ViewContext, useViewContext }